//Internal packages
import { recoveryPassword } from '../../interfaces/auth/authTypes'
import { AuthService } from '../../services/auth/AuthServices';
import { useState } from 'react';

//outer packages
import { SubmitHandler, useForm } from "react-hook-form";
import validator  from "validator";
import { toast } from 'react-toastify';

//components
import Spinner from '../common/Spinner';

//design
import  '../../styles/components/auth/RecoveryPasswordFormStyle.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Popup } from '../common/Popup';


const RecoveryPasswordForm = () => {
  //estado de mostrar que se esta cargando
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  //utilizar el paquete de useForm
  const { register, handleSubmit} = useForm<recoveryPassword>();
  //validar el for mulario antes de enviar la informacion
  const handleSubmitRecovery:SubmitHandler<recoveryPassword> = data =>{
    //validar inputs
    if( isFormValid(data) ){
      // si es correcto se envia a los endpoint
      handleSendData(data);
    }
  }
  //validar que todo el formulario este correcto
  const isFormValid = (data: recoveryPassword) : boolean=>{
    if(!validator.isEmail(data.email)){
      toast("The text is not email");
      return false;
    }
    return true;
  }

  //mandar los datos a la api y mostrar el resultado
  const handleSendData = async(data:recoveryPassword)=>{
    try{
      handleSetLoading(true);
      await AuthService.recoveryPassword(data).then((res)=>{
        toast("Mail sent successfully");
        showMessage();
      }).catch((err)=>{
        toast(err?.response?.data?.message);
      });
    }catch (error){
      toast("System error check your network");
    }
    handleSetLoading(false);
  }

  const handleSetLoading = (value: boolean)=>{
    setLoading(value);
  }

  const showMessage = ()=>{
    setShowPopup(true);
    setTimeout(()=>{
      setShowPopup(false);
  }, 7000);
  }

  //mostrar el componente de cargar cuando se necesite
  if (loading) {
    return <Spinner />;
  }


  return (
    <>
      {/* contenido de la pagina */}
        
        <div className='form_container'>
            <form onSubmit={handleSubmit(handleSubmitRecovery)} className="form">
                <p className='title_recovery'>If you have forgotten your password enter your email address below</p>
                <label className="label_form">
                  <input 
                    {...register("email")}
                    placeholder="Your email"
                    type="email"
                  />
                </label>
                <button className="btn btn_primary">Send</button>
            </form> 
        </div>
        {showPopup ?  <Popup
            title='Email sent, follow the instructions sent to reset your password'
            type='good'
          /> :''}
    </>
  );
};

export default RecoveryPasswordForm;
