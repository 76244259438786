import { ReactElement, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/auth/AuthContext";

interface props{
    children : ReactElement<any, any>
}

export const PrivateRoute = ({ children }:props) => {

  const { authState } = useContext( AuthContext );

  return authState.logged
    ? children
    : <Navigate to="/"/>
}
