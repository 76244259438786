import '../../styles/components/layouts/FooterStyle.scss';
import logoFooter from '../../assets/logos/Logo-IFS-footer.png';
export const Footer = () => {
  return (
    <div className='footer-container'>
        <p className='line-footer'></p>
        <div className='footer-items'>
            <div className='footer-container-logo'>
                <img src={logoFooter} alt="logo-footer" />
            </div>
            <div className='footer-container-copyright'>
                <p>© 2022 IFS Limited. All rights reserved.</p>
            </div>
            
        </div>
    </div>
  )
}
