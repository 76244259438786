import { SubmitHandler, useForm } from "react-hook-form";
import { newPasswordForm } from "../../interfaces/auth/authTypes";
import validator  from "validator";
import { toast } from 'react-toastify';
import { Popup } from "../common/Popup";
import { useState } from "react";
import { AuthService } from "../../services/auth/AuthServices";
import Spinner from "../common/Spinner";


const NewPasswordForm = ({handleChangeView, token='none'} : NewPasswordFormProps) => {
    
    const [showPopup, setShowPopup] = useState(false);
     //estado de mostrar que se esta cargando
    const [loading, setLoading] = useState(false);
    //utilizar el paquete de useForm
    const { register, handleSubmit} = useForm<newPasswordForm>();

    //validar el for mulario antes de enviar la informacion
    const handleSubmitPass:SubmitHandler<newPasswordForm> = data =>{
    //validar inputs
    if( isFormValid(data) ){
        // si es correcto se valida el token
        handleSendData(data);
    }
}

    //validar que todo el formulario este correcto
  const isFormValid = (data: newPasswordForm) : boolean =>{
    if(data.old_password.length < 3){
      toast("The temporal password must be greater than 2 characters");
      return false;
    }else if(data.new_password.length <3){
      toast("The new password must be greater than 2 characters");
      return false;
    }else if(data.confirmPassword.length <3){
      toast("The input confirm password must be greater than 2 characters");
      return false;
    }
    else if(!validator.equals(data.new_password, data.confirmPassword)){
        toast("The password and confirm password are different");
        return false;
      }
    return true;
  }

  //mandar los datos a la api y mostrar el resultado
  const handleSendData = async(data:newPasswordForm )=>{
    try{
      setLoading(true);
      await AuthService.chancePassword(data, token).then((res)=>{
        if(res.status === 200){
            showMessage();
          }else if(res.status === false){
            toast("The token is not the same");
          }
      }).catch((err)=>{
        toast(err?.response?.data?.message);
      });
    }catch (error){
      toast("System error check your network");
    }
    setLoading(false);
  }


  const showMessage = ()=>{
    setShowPopup(true);
    setTimeout(()=>{
      setShowPopup(false);
      handleChangeView(1);
    }, 5000);
  }

  if (loading) {
    return <Spinner />;
  }
    
    return (
        <>
            <div className="form_container">
                <div className="text-center text-xxl2 font-secundary font-medium">Password reset</div>
                <form onSubmit={handleSubmit(handleSubmitPass)} className="form">
                    <label className="label_form">
                        <input 
                            {...register("old_password")}
                            placeholder="Enter the password sent"
                            type="text"
                        />
                    </label>
                    <label className="label_form">
                        <input 
                            {...register("new_password")}
                            placeholder="Enter the new password"
                            type="password"
                        />
                    </label>
                    <label className="label_form">
                        <input 
                            {...register("confirmPassword")}
                            placeholder="Confirm the new password"
                            type="password"
                        />
                    </label>
                    <button className="btn btn_primary">Send</button>
                </form> 
            </div>
            {showPopup ?  <Popup
            title='Successful Recovery'
            text="Enter with the registered email and with the new assigned password"
            type='good'
          /> :''}
        </>
    );

}

interface NewPasswordFormProps{
    handleChangeView: React.Dispatch<React.SetStateAction<number>>,
    token?: string 
}

export default NewPasswordForm;