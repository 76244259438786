import corporateImage from "../../assets/images/items/image-corporate.png"
import iconLocation from '../../assets/icons/location-icon.png';

const FooterAuth = () => {

  return (
    <div className="footer-class">
        <hr className="footer-divider"></hr>
        {window.outerWidth > 600  ? <div className="content-footer">
            <div className="box display-flex">
              <img src={iconLocation} alt="eye-icon" className="icon-location" width="15px" height="20px"/>
              <p>Innovation House 56 Reid Street, 4th Floor  Hamilton, HM12, Bermuda</p>
            </div>
            <div className="box item-left">
              <img src={corporateImage} alt="" />
            </div> 
        </div>  : <div style={{justifyContent: "center", textAlign: "left", padding: "10px"}}>
        <div className="box display-flex">
              <img src={iconLocation} alt="eye-icon" className="icon-location" width="15px" height="20px"/>
              <p style={{fontSize: '14px'}}>Innovation House 56 Reid Street, 4th Floor  Hamilton, HM12, Bermuda</p>
            </div>
            <div className='footer-items-corporate'>
              <img src={corporateImage} alt="" />
            </div>
        </div> }
        <p className="text-bottom-footer">© 2022 IFS Limited. All rights reserved.</p>
      </div>
  )
};

export default FooterAuth;
