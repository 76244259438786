//outer packages
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './context/auth/AuthProvider';
import 'animate.css';
import './styles/common.scss';
//components
import Routes from './routes/index';

//componente inicial de la aplicacion
function App() {
  return (
    <AuthProvider>
      <Routes />
      {/* //contenedor para las notificaciones */}
      <ToastContainer />
    </AuthProvider>
    
  );
}

export default App;
