// import { User} from '../../../interfaces/admin/access/accessTypes';
import { status} from '../../../interfaces/admin/access/accessTypes';
import { AuthState } from '../../../interfaces/context/authContext/authContextInterface';
import {  ApiResponseGetAllUser, ApiResponseGetSelectStates } from '../../../interfaces/response/ApiResponse';
import { ifsService } from '../../IfsService';



export class AccessService{
   //traer datosd para pintarlos en el modulo administrativo
   public static async getAllUsers(userAuthenticated: AuthState): Promise<ApiResponseGetAllUser>{
      return ( await ifsService.getH('users', userAuthenticated) )
   }
   //llevar los datos para comprobar si son correctos para el inicio de sesion
   public static async getAllStateSelect(userAuthenticated: AuthState): Promise<ApiResponseGetSelectStates>{
      return ( await ifsService.getH('status_users',userAuthenticated) )
   }
   //llevar los datos para comprobar si son correctos para el inicio de sesion
   public static async updateStatePerson(idPerson:string,status: status, userAuthenticated: AuthState): Promise<ApiResponseGetSelectStates>{
      const statusId = {
         status: status.id
      }
      return ( await ifsService.putH('users/status/'+idPerson, statusId, userAuthenticated.accessToken))
   }
   //eliminar un usuario
   public static async deletePerson(idPerson:string, userAuthenticated: AuthState): Promise<ApiResponseGetSelectStates>{
      return ( await ifsService.deleteH(`users/${idPerson}`, userAuthenticated) )
   }
}