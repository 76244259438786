//design
import '../../styles/components/layouts/HeaderStyle.scss';
import logoIFS from '../../assets/logos/logoIFS.png';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth/AuthContext';
import { useNavigate } from 'react-router-dom';


//header de la pagina ifs limited
export const Header = () => {
  const { toggleLogout } = useContext( AuthContext );
  const navigate = useNavigate();

  //cerrar sesion
  const handleLogout = ()=>{
    toggleLogout();
    //redirecionar
    navigate('/', {
      replace:true
    });
  }

  return (
    <div className="header">
      <img src={logoIFS} alt="logoIFS" className='image_logo'></img>
      <button className='btn-logout' onClick={handleLogout}>Logout</button>
    </div>
  )
}
