//outer packages
import { Routes as Switch, Route, BrowserRouter } from "react-router-dom";
//Components
import Auth from '../views/auth';

import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import { DasboardRoutes } from "./DasboardRoutes";

//rutas de la pagina
const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
          <Route path="/:auth" element={
            <PublicRoute>
              <Auth />
            </PublicRoute>
        }
         />
         <Route path="/" element={
            <PublicRoute>
              <Auth />
            </PublicRoute>
        }
         />
          <Route path="/admin/*" element={
            <PrivateRoute>
              <DasboardRoutes/>
            </PrivateRoute>} 
          />
        </Switch>
      </BrowserRouter>
    
  //  component={Auth}
  );
};

export default Routes;
