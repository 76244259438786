//Internal package
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

//components
import SignupForm from "../../components/auth/SignupForm";
import LoginForm from "../../components/auth/LoginForm";
import RecoveryPasswordForm from "../../components/auth/RecoveryPasswordForm";
import { HeaderLogin } from "../../components/layouts/HeaderLogin";
//design
import "../../styles/views/auth/authStyle.scss";
import imageBackground1 from "../../assets/images/backgrounds/background.png";
import imageBackground2 from "../../assets/images/backgrounds/background_base.png";
import NewPasswordForm from "../../components/auth/NewPasswordForm";
import FooterAuth from "../../components/layouts/FooterAuth";

//vista de autenticacion
const Auth = () => {
  //controlar las vistas de la autenticacion login, signup , recoverypassword and changePassword
  const [formView, setFormView] = useState(1);
  const {auth} = useParams();
  useEffect(() => {
    auth && setFormView(4);
  }, [auth])
  

  return (
    <div style={{minHeight: "100%", position: "relative", marginBottom: "140px"}}>
      <HeaderLogin />
      {/* background de la pagina login */}
      <div className="capa_background">
        <img
          src={imageBackground1}
          className="background1"
          alt={"background"}
        />
        <img
          src={imageBackground2}
          className="background2"
          alt={"background"}
        />
        <div className="background3"></div>
      </div>

      {/* contenido de la pagina */}
      <div style={{maxWidth: '1650px', margin:'auto', paddingBottom: "140px"}}>
      <div className="container_auth">
        <div className="text_left">
          {/* <h1 className="title">
            Grow your {window.outerWidth > 1000 ? <br /> : null}
            businness {window.outerWidth > 1000 ? <br /> : null}
            with us
          </h1> */}
          <p className="subtitle">
          <strong>IFS Limited</strong>  is a Bermuda based diversified financial services holding company with insurance and lending operations in the Americas. Founded in 2017, IFS is rated B1 by Moody’s Investor Services and is an active debt issuer in the Bermuda Stock Exchange (BSX).
          </p>
        </div>
        <div className="container_auth_form">
          <div className="nav_auth">
            <div
              className={formView === 2 ? "button_active" : "button_disable"}
            >
              <button onClick={() => setFormView(2)}>SIGN UP</button>
            </div>
            <div
              className={
                formView === 1 || formView === 3 || formView === 4
                  ? "button_active"
                  : "button_disable"
              }
            >
              <button onClick={() => setFormView(1)}>LOGIN</button>
            </div>
          </div>
          {/* traer vista de login al usuario */}
          {formView === 1 && <LoginForm handleChangeView={setFormView} />}
          {/* traer la vista de registro de un usuario */}
          {formView === 2 && <SignupForm />}
          {/* traer vista de recuperar contrasena */}
          {formView === 3 && (
            <RecoveryPasswordForm />
          )}
           {formView === 4 && (
            <NewPasswordForm handleChangeView={setFormView} token={auth}/>
          )}
        </div>
      </div>
    </div>
    <FooterAuth />
    </div>
  );
};

export default Auth;
